<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="800px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="所属门店：" prop="shopId">
            <el-select
              v-model="form.shopId"
              placeholder="请选择"
              :disabled="mode === 'edit'"
              @change="shopChangeHandle"
              clearable 
              filterable
            >
              <el-option
                v-for="(item, index) in shopOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类ID：" prop="categoryId">
            <el-select v-model="form.categoryId" placeholder="请选择" :disabled="!form.shopId">
              <el-option
                v-for="(item, index) in categoryOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品标题：" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="产品副标题：" prop="subtitle">
            <el-input v-model="form.subtitle"></el-input>
          </el-form-item>
          <el-form-item label="简介：" prop="subdesc">
            <el-input v-model="form.subdesc"></el-input>
          </el-form-item>
          <el-form-item label="主图：" prop="image">
            <uploads v-model="form.image"></uploads>
          </el-form-item>
          <el-form-item label="组图：" prop="images">
            <uploads v-model="form.images"></uploads>
          </el-form-item>
          <el-form-item label="是否产品组：" prop="type">
            <el-radio-group v-model="form.type" @change="typeChangeHandle">
              <el-radio label="02">是</el-radio>
              <el-radio label="01">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="form.type === '02'"
            label="子产品："
            prop="productIds"
          >
            <el-select v-model="form.productIds" placeholder="请选择" multiple>
              <el-option
                v-for="(item, index) in subProductOptions"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 非产品组才能控制规格 -->
          <el-form-item
            v-if="form.type === '01'"
            label="选用规格："
            prop="useSpec"
          >
            <el-radio-group v-model="form.useSpec">
              <el-radio :label=1>是</el-radio>
              <el-radio :label=0>否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="form.useSpec === 1 && form.type === '01'"
            label="规格："
            prop="useSpec"
          >
            <specification
              v-if="modelValue"
              ref="spec"
              :specList="this.form.specList"
              :specTableList="this.form.specTableList"
            ></specification>
          </el-form-item>
          <el-form-item label="物联设备一级菜单：" prop="deviceFirstName" v-if="form.type === '02'">
            <el-input v-model="form.deviceFirstName"></el-input>
          </el-form-item>
          <el-form-item label="最低市场价：" prop="marketPrice">
            <el-input v-model="form.marketPrice" type="number"></el-input>
          </el-form-item>
          <el-form-item label="最低销售价：" prop="salesPrice">
            <el-input v-model="form.salesPrice" type="number"></el-input>
          </el-form-item>
          <el-form-item label="总库存：" prop="stock">
            <el-input v-model="form.stock" type="number"></el-input>
          </el-form-item>
          <el-form-item label="总虚拟销量：" prop="sales">
            <el-input v-model="form.sales" type="number"></el-input>
          </el-form-item>
          <el-form-item label="详情：" prop="desc">
            <el-input v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item label="虚拟浏览量：" prop="look">
            <el-input v-model="form.look" type="number"></el-input>
          </el-form-item>
          <el-form-item label="权重：" prop="weigh">
            <el-input v-model="form.weigh" type="number"></el-input>
          </el-form-item>

          <el-form-item label="热门推荐：" prop="ishotswitch">
            <el-radio-group v-model="form.ishotswitch">
              <el-radio :label=1>是</el-radio>
              <el-radio :label=0>否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否上架：" prop="switchFlag">
            <el-radio-group v-model="form.switchFlag">
              <el-radio :label=1>是</el-radio>
              <el-radio :label=0>否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getCategoryList,listAll } from "@/apis/unidrink/category";
import {
  addProduct,
  editProduct,
  getSubProductOptions,
} from "@/apis/unidrink/product";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
import specification from "./specification.vue";
import { getShopOptions } from "@/apis/common";
export default {
  components: { specification },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
  },
  data() {
    return {
      initForm: {
        status: "normal",
        pid: 0,
        ishotswitch: 0,
        switchFlag: 1,
        useSpec: 0,
        desc: "",
        condimentId: "", // 默认字段
        marketPrice: 0,
        salesPrice: 0,
        stock: 0,
        sales: 0,
        look: 0,
        weigh: 0,
        shopId: "",
        realSales: 0,
        realLook: 0,
        noBuyYet: 0,
        type: "01",
        productIds: []
      },
      form: {},
      rules: {
        categoryId: [
          {
            required: true,
            message: "分类不能为空",
            trigger: ["blur", "change"],
          },
        ],
        title: [
          { required: true, message: "产品标题不能为空", trigger: "blur" },
        ],
        subtitle: [
          { required: true, message: "产品副标题不能为空", trigger: "blur" },
        ],
        subdesc: [{ required: true, message: "简介不能为空", trigger: "blur" }],
        deviceFirstName:[{ required: true, message: "物联设备一级菜单不能为空", trigger: "blur" }],
        image: [{ required: true, message: "主图不能为空", trigger: "blur" }],
        images: [{ required: true, message: "组图不能为空", trigger: "blur" }],
        marketPrice: [
          { required: true, message: "最低市场价不能为空", trigger: "blur" },
        ],
        salesPrice: [
          { required: true, message: "最低销售价不能为空", trigger: "blur" },
        ],
        stock: [{ required: true, message: "总库存不能为空", trigger: "blur" }],
        sales: [
          { required: true, message: "总虚拟销量不能为空", trigger: "blur" },
        ],
        look: [
          { required: true, message: "虚拟浏览量不能为空", trigger: "blur" },
        ],
        weigh: [{ required: true, message: "权重不能为空", trigger: "blur" }],
        productIds: [
          { required: true, message: "子产品不能为空", trigger: "blur" },
        ],
        shopId: [
          {
            required: true,
            message: "门店不能为空",
            trigger: "blur",
          },
        ],
      },
      shopOptions: [],
      categoryOptions: [],
      subProductOptions: [],
    };
  },
  emits: ["update:modelValue", "refresh"],
  created() {
    this.form = cloneDeep(this.initForm);
  },
  watch: {
    modelValue(val) {
      if (val) {
        getShopOptions(this.shopOptions, false);
        // this.getCategoryOptions();
        this.resetForm();
        this.editReflect();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit" || this.mode === "copy") {
        this.form = cloneDeep(this.editData);
        console.log(this.form);
        this.form.shopId = Number(this.form.shopId);
        this.form.categoryId = Number(this.form.categoryId);

        const productIdsTemp = this.form.productIds;
        this.shopChangeHandle(this.form.shopId);
        this.form.productIds = productIdsTemp
          ? productIdsTemp.split(",").map((item) => Number(item))
          : [];
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        if (this.$refs.spec) {
          const { specList, specTableList } = this.$refs.spec.getResult();
          this.form = {
            ...this.form,
            specList,
            specTableList,
          };
        }

        this.form.productIds = this.form.productIds.join(",");

        const promise = this.mode === "edit" ? editProduct : addProduct;
        promise(this.form).then(({ code, message }) => {
          if (code === "0000") {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        });
      });
    },

    getCategoryOptions() {
      // getCategoryList("all").then(({ rows, total }) => {
      //   this.categoryOptions.push(
      //     ...rows.map((row) => {
      //       return {
      //         ...row,
      //         name: row.name.replace(/[&nbsp;|└]/g, ""),
      //       };
      //     })
      //   );
      // });
      if(!this.form.shopId){
        return;
      }
      listAll({shopId:this.form.shopId}).then(({ code, result, message }) => {
        if (code !== "0000") {
          msgErr(message);
          return;
        }
        const { list, total } = result;
        this.categoryChooseOptions.push(...list.map((row) => {
          return {
            label: row.name.replace(/[&nbsp;|└]/g, ""),
            value: row.id,
          };
        }));
        this.activeTab = "0";
      });
    },

    shopChangeHandle(value) {
      console.log("shopChangeHandle");
      getSubProductOptions(this.subProductOptions, {
        shopId: value,
        type: "01",
      });
      this.form.productIds = [];
      this.getCategoryOptions();
    },

    typeChangeHandle(value) {
      if (value === "02") {
        this.form.useSpec = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
